<template>
  <div class="main-container">
    <div class="container-two-rows center-items">
      <span class="white-text font-size-22">{{ guestName }},</span>
      <span class="white-text">los esperamos para celebrar juntos el día</span>
    </div>
    <div class="container-one-row center-items">
      <div class="border-top-bottom">
        <span class="white-text font-size-25">Sábado</span>
      </div>
      <div class="party-date-container">
        <div class="mt-5">
          <span class="party-date">20</span>
        </div>
      </div>
      <div class="border-top-bottom">
        <span class="white-text font-size-25">Julio</span>
      </div>
    </div>
    <div class="center-items">
      <span class="white-text font-size-30">2024</span>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";

export default {
  name: "PartyDateView",
  props: {
    nameFamily: Object,
  },
  setup(props) {
    const guestName = ref("");

    // Utilizamos watch para reaccionar a cambios en nameFamily
    watchEffect(() => {
      const data = props.nameFamily;
      if (data != null) {
        guestName.value = data["nombre_invitado"];
      }
    });

    return {
      guestName,
    };
  },
};
</script>
<style scoped>
.main-container {
  width: 80%;
  margin: 0;
  padding: 25px 10%;
  background-color: #89000a;
  position: relative;
}

.center-items {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container-one-row {
  display: flex;
  margin: 20px 0;
}

.container-two-rows {
  display: grid;
  grid-template-rows: auto auto;
}

.white-text {
  font-family: "DM Serif Text", serif;
  color: white;
  font-size: 16px;
  line-height: 29px;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-25 {
  font-size: 25px;
  line-height: 34px;
}

.font-size-30 {
  font-size: 30px;
  line-height: 30px;
}

.border-top-bottom {
  padding: 10px 0;
  border-top: white solid;
  border-bottom: white solid;
  width: 100px;
}

.party-date-container {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 100%;
  margin: 0 10px;
}

.party-date {
  color: #89000a;
  font-size: 58px;
  line-height: 70px;
}
</style>

<template>
  <div class="main-container">
    <div id="main-begining-container">
      <img
        id="flowers-1"
        src="./images/flowers_1.png"
        alt="Flowers 1"
        draggable="false"
      />
      <img
        id="points-right"
        src="./images/points_right.png"
        alt=" Points Right"
        draggable="false"
      />
      <img
        id="celebrate_with_us"
        src="./images/celebrate_with_us.png"
        alt="Celebrate With Us"
        draggable="false"
      />
      <img
        id="reserve_the_day"
        src="./images/reserve_the_day.png"
        alt="Reserve The Day"
        draggable="false"
      />
      <div id="vladimir-zulma-content" class="center-items pt-60">
        <img
          id="vladimir-zulma-portrait"
          src="./images/vladimir_zulma.png"
          alt="Vladimir and Zulma portrait"
          draggable="false"
        />
        <div id="vladimir-zulma-text-container">
          <span id="vladimir-zulma-text"> Vladimir Y <br />Zulma </span>
          <img
            id="flowers-2"
            src="./images/flowers_2.png"
            alt="Flowers 2"
            draggable="false"
          />
        </div>
      </div>
      <div id="our-30-anniversary-container" class="center-items mt-15">
        <span id="our-30-anniversary"> Nuestro 30° Aniversario </span>
      </div>
      <hr class="custom-hr" />
      <img
        id="points-left"
        src="./images/points_left.png"
        alt=" Points Left"
        draggable="false"
      />
      <div class="h-25"></div>
      <div class="h-10"></div>
    </div>
  </div>
  <div id="biblical-quote-main-container">
    <img
      id="rounded-image"
      src="./images/rounded-image.png"
      alt="Rounded Image"
      draggable="false"
    />
    <div id="biblical-quote-container">
      <span class="biblical-quote">
        Uno solo puede ser vencido, pero dos podrán resistir. Y además, la
        cuerda de tres hilos no se rompe fácilmente.
      </span>
      <div class="h-25"></div>
      <span class="biblical-quote"> Eclesiastés 4, 12 </span>
    </div>
    <div class="h-25"></div>
    <div class="h-10"></div>
    <img
      id="flowers_3"
      src="./images/flowers_3.png"
      alt="Flowers 3"
      draggable="false"
    />
    <div class="h-25"></div>
    <div class="h-10"></div>
  </div>
</template>

<script>
export default {
  name: "CelebrateWithUsView",
};
</script>
<style scoped>
.main-container {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fcf6f1;
  position: relative;
  z-index: 1;
}

#main-begining-container {
  background-color: white;
  /*
  border-radius: 0 0 50% 50% / 0 0 120px 120px;
  */
  z-index: 1;
}

#rounded-image {
  width: 100%;
}

#flowers-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
}

#points-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
}

#points-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
}

#celebrate_with_us {
  position: absolute;
  top: 0;
  left: 0;
  width: 48%;
  margin-top: 10%;
  margin-left: 2.5%;
}

#reserve_the_day {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 5%;
  margin-bottom: 23.5%;
  margin-right: 6%;
}

.center-items {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#our-30-anniversary-container {
  display: grid;
}

#vladimir-zulma-content {
  position: relative;
}

#vladimir-zulma-portrait {
  width: 72%;
  min-height: 460px;
}

#vladimir-zulma-text-container {
  position: absolute;
  bottom: 0;
  text-align: center;
  margin-bottom: 10px;
  display: grid;
  grid-row: 2;
}

#vladimir-zulma-text {
  font-family: "Alex Brush", cursive;
  font-size: 50px;
  line-height: 53px;
  color: white;
}

#flowers-2 {
  margin: 0 auto;
  width: 60% !important;
}

#our-30-anniversary {
  font-family: "DM Serif Text", serif;
  color: #89000a;
  font-size: 22px;
}

.custom-hr {
  width: 72%;
  height: 1px;
  background-color: #9b96927a;
  border: none;
}

#biblical-quote-main-container {
  width: 100% !important;
  margin: 0 auto;
  text-align: center;
  background-color: #fcf6f1;
}

#biblical-quote-container {
  width: 80% !important;
  margin: 0 auto;
  text-align: center;
  display: grid;
  align-items: center;
}

.biblical-quote {
  font-family: "Marck Script";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9b9692;
}

#flowers_3 {
  width: 80%;
}
</style>

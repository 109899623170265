<template>
  <div id="gift-detail-container">
    <span id="gift-detail-text">
      Si desean celebrar con un regalo, <br />
      preferiríamos recibirlo en forma de sobre. Su <br />
      presencia ya es un regalo invaluable, y <br />
      cualquier contribución será recibida con <br />
      gratitud y amor.
    </span>
    <div class="h-50"></div>
    <img
      id="gift-image"
      src="./image/gift_image.png"
      alt="Gift Detail"
      draggable="false"
    />
    <div class="h-50"></div>
  </div>
</template>
<script>
export default {
  name: "GiftDetailView",
};
</script>
<style scoped>
#gift-detail-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 50px;
}
#gift-detail-text {
  text-align: center;
  font-family: "DM Serif Text";
  font-size: 16px;
  line-height: 22px;
  color: #9b9692;
}

#gift-image {
  width: 80%;
  margin-left: 10%;
}
</style>

<template>
  <div class="main-container">
    <img
      id="flowers"
      src="./images/flowers.png"
      alt="Flowers"
      draggable="false"
    />
  </div>
  <div class="h-75"></div>
  <div class="image-text-container mt-100">
    <img
      id="church-image"
      src="./images/church_image.png"
      alt="Iglesia Ricaldone"
      draggable="false"
    />
    <div id="text-button-container">
      <div class="h-10"></div>
      <span class="red-text"> Parroquia San Juan Bautista </span>
      <div class="h-10"></div>
      <button class="custom-button" @click="openChurch">
        <span class="text-buttom"> Ver mapa </span>
      </button>
    </div>
  </div>
  <div class="h-50"></div>
  <div class="image-text-container mt-100">
    <img
      id="reception-image"
      src="./images/reception_image.png"
      alt="Recepción: Colproce"
      draggable="false"
    />
    <div id="text-button-container">
      <div class="h-10"></div>
      <span class="red-text"> Recepción: Colproce </span>
      <div class="h-10"></div>
      <button class="custom-button" @click="openReception">
        <span class="text-buttom"> Ver mapa </span>
      </button>
    </div>
  </div>
  <div class="h-75"></div>
</template>

<script>
export default {
  name: "ChurchReceptionView",
  methods: {
    openChurch() {
      const latitude = 13.686288; // Reemplaza con la latitud de la ubicación deseada
      const longitude = -89.236561; // Reemplaza con la longitud de la ubicación deseada
      const url = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;

      window.open(url, "_blank");
    },

    openReception() {
      const latitude = 13.6824513; // Reemplaza con la latitud de la ubicación deseada
      const longitude = -89.2350384; // Reemplaza con la longitud de la ubicación deseada
      const url = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;

      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#flowers {
  position: absolute;
  top: 0;
  right: 0;
  width: 18%;
}

#church-image {
  width: 50%;
}

#text-button-container {
  width: 80% !important;
  margin: 0 auto;
  text-align: center;
  display: grid;
  align-items: center;
}

.red-text {
  font-family: "DM Serif Text", serif;
  color: #89000a;
  font-size: 22px;
}

.image-text-container {
  width: 100% !important;
  margin: 0 auto !important;
  text-align: center !important;
}

#reception-image {
  width: 45%;
}

.custom-button {
  width: 120px;
  background-color: #89000a;
  border-radius: 32px;
  padding: 6px 0;
  border: none;
  margin: 0 auto;
}

.text-buttom {
  color: white;
  font-family: "DM Serif Text";
  font-size: 16px;
  line-height: 24px;
}

/*
///////////////////////////////////////////
*/

#biblical-quote-main-container {
  width: 100% !important;
  margin: 0 auto;
  text-align: center;
  background-color: #fcf6f1;
  height: 300px;
}

.biblical-quote {
  font-family: "Marck Script";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9b9692;
}
</style>

<template>
  <div>
    <CelebrateWithUsViewVue />
    <PartyDateView :nameFamily="information" />
    <ChurchReceptionView />
    <ItineraryView />
    <GiftDetailViewVue />
    <FamilyReservationView :reservation="information" />
    <AsistementConfirmationViewVue
      :asistementInformation="information"
      :sendResponseToAttend="sendResponseToAttend"
    />
    <PicturesFarewellView />
  </div>
</template>
<script>
import AsistementConfirmationViewVue from "./invitation_parts/asistement_confirmation/AsistementConfirmationView.vue";
import CelebrateWithUsViewVue from "./invitation_parts/celebrate_with_us/CelebrateWithUsView.vue";
import ChurchReceptionView from "./invitation_parts/church_reception/ChurchReceptionView.vue";
import FamilyReservationView from "./invitation_parts/family_reservation/FamilyReservationView.vue";
import GiftDetailViewVue from "./invitation_parts/gift_details/GiftDetailView.vue";
import ItineraryView from "./invitation_parts/itinerary/ItineraryView.vue";
import PartyDateView from "./invitation_parts/party_darte/PartyDateView.vue";
import PicturesFarewellView from "./invitation_parts/pictures_farewell/PicturesFarewellView.vue";

export default {
  name: "InvitationVue",
  props: {
    information: Object,
    sendResponseToAttend: Function,
  },
  components: {
    CelebrateWithUsViewVue,
    PartyDateView,
    ChurchReceptionView,
    ItineraryView,
    GiftDetailViewVue,
    FamilyReservationView,
    AsistementConfirmationViewVue,
    PicturesFarewellView,
  },
};
</script>

<template>
  <div v-if="WillYouAttend == 1">
    <div id="no-asistement-container">
      <div class="h-25"></div>
      <div class="h-10"></div>
      <img
        id="noAsistementImage"
        src="./image/no_asistement.png"
        alt="No asistement"
        draggable="false"
      />
      <div class="h-10"></div>
      <span id="text">
        Lamentamos saber que no podrás asistir<br />
        a nuestra fiesta.!Esperamos poder <br />
        celebrar juntos en otra ocasión!
      </span>
      <div class="h-50"></div>
      <button class="custom-button" @click="changeOpinion">
        <span class="text-buttom">¡He cambiado de opinión!</span>
      </button>
      <div class="h-25"></div>
      <div class="h-10"></div>
    </div>
  </div>
  <div v-else-if="WillYouAttend == 2">
    <div id="yes-asistement-container">
      <div class="h-25"></div>
      <div class="h-10"></div>
      <img
        id="yesAsistementImage"
        src="./image/yes_asistement.png"
        alt="yes asistement"
        draggable="false"
      />
      <div class="h-10"></div>
      <span id="red-text">
        Nos alegra saber que podrás asistir a<br />
        nuestra fiesta. !Esperamos emocionados de<br />
        tenerte y te esperamos con ansias!
      </span>
      <div class="h-50"></div>
      <button class="custom-button-3" @click="changeOpinion">
        <span class="text-buttom-3">¡He cambiado de opinión!</span>
      </button>
      <div class="h-25"></div>
      <div class="h-10"></div>
    </div>
  </div>
  <div v-else>
    <div id="asistement-confirmation-container">
      <div class="h-25"></div>
      <div class="h-10"></div>
      <div class="h-10"></div>
      <span id="text">
        Es muy importante para nosostros que <br />
        confirmes tu asistencia
      </span>
      <div class="h-50"></div>
      <button class="custom-button" @click="responseToAttend(2)">
        <span class="text-buttom"> Sí podré asistir</span>
      </button>
      <div class="h-10"></div>
      <div class="h-10"></div>
      <div class="h-10"></div>
      <div class="h-10"></div>
      <button class="custom-button-2" @click="responseToAttend(1)">
        <span class="text-buttom-2"> No podré acompañarlos</span>
      </button>
      <div class="h-25"></div>
      <div class="h-10"></div>
    </div>
  </div>
</template>
<script>
import { ref, watchEffect } from "vue";

export default {
  name: "AsistementConfirmationView",
  props: {
    asistementInformation: Object,
    sendResponseToAttend: Function,
  },
  setup(props) {
    const WillYouAttend = ref(0);

    watchEffect(() => {
      const data = props.asistementInformation;
      if (data != null) {
        WillYouAttend.value = data["confirmacion_asistencia"];
      }
    });

    return {
      WillYouAttend,
    };
  },
  methods: {
    changeOpinion() {
      this.WillYouAttend = 0;
    },
    async responseToAttend(parameter) {
      this.WillYouAttend = parameter;
      await this.sendResponseToAttend(parameter);
    },
  },
};
</script>
<style scoped>
#asistement-confirmation-container {
  background-color: #89000a;
  text-align: center;
}

#no-asistement-container {
  background-color: #da8b86;
  text-align: center;
}

#yes-asistement-container {
  background-color: #ffffff;
  text-align: center;
}

#text {
  font-family: "DM Serif Text";
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

#red-text {
  font-family: "DM Serif Text";
  font-size: 18px;
  text-align: center;
  color: #7b0100;
}

#noAsistementImage {
  margin: 0 auto !important;
  width: 40%;
}

#yesAsistementImage {
  margin: 0 auto !important;
  width: 40%;
}

.custom-button {
  width: 220px;
  background-color: white;
  border-radius: 5px;
  padding: 6px 0;
  border: none;
  margin: 0 auto;
}

.text-buttom {
  color: #89000a;
  font-family: "DM Serif Text";
  font-size: 16px;
  line-height: 24px;
}

.custom-button-2 {
  width: 220px;
  background-color: #da8b86;
  border-radius: 5px;
  padding: 6px 0;
  border: none;
  margin: 0 auto;
}

.text-buttom-2 {
  color: white;
  font-family: "DM Serif Text";
  font-size: 16px;
  line-height: 24px;
}

.custom-button-3 {
  width: 220px;
  background-color: #7b0100;
  border-radius: 5px;
  padding: 6px 0;
  border: none;
  margin: 0 auto;
}

.text-buttom-3 {
  color: white;
  font-family: "DM Serif Text";
  font-size: 16px;
  line-height: 24px;
}
</style>

<template>
  <div id="family-reservation-container" class="center-items">
    <div class="h-75"></div>
    <span id="text"> Lugares reservados en su honor </span>
    <div class="h-10"></div>
    <div class="h-10"></div>
    <div class="amount-reservation">
      <div class="mt-5">
        <span class="amount-reservation-number">{{ ammountReservation }}</span>
      </div>
    </div>
    <div class="h-75"></div>
  </div>
</template>
<script>
import { ref, watchEffect } from "vue";

export default {
  name: "FamilyReservationView",
  props: {
    reservation: Object,
  },
  setup(props) {
    const ammountReservation = ref(0);

    watchEffect(() => {
      const data = props.reservation;
      if (data != null) {
        ammountReservation.value = data["numero_invitados"];
      }
    });

    return {
      ammountReservation,
    };
  },
};
</script>
<style scoped>
#family-reservation-container {
  background-color: #fcf6f1;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
}

.center-items {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#text {
  font-family: "DM Serif Text";
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #89000a;
}

.amount-reservation {
  width: 84px;
  height: 84px;
  background-color: #89000a;
  border-radius: 100%;
  margin: 0 auto;
}

.amount-reservation-number {
  color: white;
  font-size: 58px;
  line-height: 70px;
}
</style>

<template>
  <div id="envelope">
    <div id="seal-container" class="seal-container">
      <div id="seal-image-container">
        <img
          id="seal-image"
          src="@/assets/envelope/seal.png"
          alt="Seal"
          draggable="false"
          ref="miImagen"
        />
        <!--@load="iniciarDesplazamiento"-->
      </div>
    </div>
    <div class="envelope-left">
      <img
        src="@/assets/envelope/left_envelope_part.png"
        alt="left_envelope_part"
        draggable="false"
        ref="cuerpoSobre"
      />
    </div>
    <div id="envelopeRight" class="envelope-right">
      <img
        src="@/assets/envelope/right_envelope_part.svg"
        alt="right_envelope_part"
        draggable="false"
        ref="solapaSobre"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "EnvelopeView",
  props: {
    showInvitationViewFunction: Function,
  },

  mounted() {
    this.iniciarDesplazamiento();
  },
  methods: {
    iniciarDesplazamiento() {
      setTimeout(() => {
        this.$refs.miImagen.classList.add("desplazado");
      }, 2000);

      setTimeout(() => {
        this.showInvitationViewFunction();
      }, 3500);

      setTimeout(() => {
        this.$refs.solapaSobre.classList.add("desplazado-derecha");
      }, 3500);

      setTimeout(() => {
        this.$refs.cuerpoSobre.classList.add("desplazado");
      }, 3500);
    },
  },
};
</script>

<style scoped>
#envelope {
  width: 100%;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  display: relative;
}

.envelope-left,
.envelope-right,
.seal-container {
  position: absolute;
}
.envelope-left {
  width: 100%;
  z-index: 9;
}

.envelope-left img {
  width: 100%;
  height: 100vh !important;
  position: fixed;
  left: 0;
  transition: transform 2s ease;
}
.envelope-right {
  width: 100%;
  overflow: hidden;
  right: 0;
  z-index: 9;
}

.envelope-right img {
  width: 55%;
  height: 100vh !important;
  position: fixed;
  right: 0;
  transition: transform 2s ease;
}

.seal-container {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
  z-index: 98;
}

#seal-image-container {
  width: 250px;
  z-index: 99;
  overflow-x: hidden;
}

#seal-image {
  width: 82%;
  transition: transform 2s ease;
}

.desplazado {
  transform: translateX(
    -110%
  ); /* Desplaza la imagen hacia la izquierda (fuera del contenedor) */
}
.desplazado-derecha {
  transform: translateX(
    110%
  ); /* Desplaza la imagen hacia la izquierda (fuera del contenedor) */
}
</style>

<template>
  <div id="container">
    <div class="h-25"></div>
    <div class="h-10"></div>
    <img
      id="group-image"
      src="./images/images_group.png"
      alt="group Image"
      draggable="false"
    />
    <div class="h-50"></div>
    <span id="text"> ¡Te esperamos! </span>
    <div class="h-50"></div>
  </div>
</template>
<script>
export default {
  name: "PicturesFarewellView",
};
</script>
<style scoped>
#container {
  text-align: center;
}

#group-image {
  width: 85%;
}

#text {
  font-family: "Allura";
  font-size: 63.9018px;
  line-height: 80px;
  color: #000000;
  text-align: center;
}
</style>

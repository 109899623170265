<template>
  <div id="itinerary-container">
    <img id="line-image" src="./image/line.png" alt="Line" draggable="false" />
    <div id="container">
      <div id="first-part-itinerary">
        <img
          id="flowers-top-left"
          src="./image/flowers_top_left.png"
          alt="Flowers Top Left"
          draggable="false"
        />
        <div id="ceremonia-recepcion">
          <div id="itinerario-container">
            <hr class="hr-itinerario" />
            <div id="itinerario-text-container">
              <span id="itinerario-text">Itinerario</span>
            </div>
            <hr class="hr-itinerario" />
          </div>
        </div>
        <div id="church-reception">
          <div id="church-container">
            <img
              id="church_image"
              src="./image/church.png"
              alt="Church"
              draggable="false"
            />
            <div class="h-10"></div>
            <div class="h-10"></div>
            <div class="text-container">
              <span class="tittle-action">Ceremonia</span>
              <span class="hour-action">6:00 PM</span>
            </div>
          </div>
          <div id="reception-container">
            <div class="left-container">
              <img
                id="wine-glasses"
                src="./image/wine_glasses.png"
                alt="Wine Glasses"
                draggable="false"
              />
            </div>
            <div class="right-container">
              <img
                id="heart_image"
                src="./image/heart.png"
                alt="Heart"
                draggable="false"
              />
              <div class="text-container move-to-right h-10">
                <span class="tittle-action">Recepción</span>
                <span class="hour-action">7:00 PM</span>
              </div>
            </div>
          </div>
        </div>
        <img
          id="flowers-top-right"
          src="./image/flowers_top_right.png"
          alt="Flowers Top Right"
          draggable="false"
        />
      </div>
      <div id="second-part-itinerary">
        <div id="photography-container">
          <div class="left-container">
            <img
              id="heart_image"
              src="./image/heart.png"
              alt="Heart"
              draggable="false"
            />
            <div class="text-container move-to-right h-10">
              <span class="tittle-action">Fotografías</span>
              <span class="hour-action">7:00 PM</span>
            </div>
          </div>
          <div class="right-container">
            <img
              id="camera"
              src="./image/camera.png"
              alt="Camera"
              draggable="false"
            />
          </div>
        </div>
      </div>
      <div id="third-part-itinerary">
        <img
          id="flowers-bottom-left"
          src="./image/flowers_bottom_left.png"
          alt="Flowers bottom Left"
          draggable="false"
        />
        <div id="dinner-container">
          <div class="left-container">
            <img
              id="dinner"
              src="./image/dinner.png"
              alt="Dinner"
              draggable="false"
            />
          </div>
          <div class="right-container">
            <img
              id="heart_image"
              src="./image/heart.png"
              alt="Heart"
              draggable="false"
            />
            <div class="text-container move-to-right h-10">
              <span class="tittle-action">Cena</span>
              <span class="hour-action">7:30 PM</span>
            </div>
          </div>
        </div>
        <div id="dance-container">
          <div class="left-container">
            <img
              id="heart_image"
              src="./image/heart.png"
              alt="Heart"
              draggable="false"
            />
            <div class="text-container move-to-right h-10">
              <span class="tittle-action">Baile</span>
              <span class="hour-action">8:30 PM</span>
            </div>
          </div>
          <div class="right-container">
            <img
              id="dance"
              src="./image/dance.png"
              alt="Dance"
              draggable="false"
            />
          </div>
        </div>
        <div id="cake-container">
          <div class="left-container">
            <img
              id="cake"
              src="./image/cake.png"
              alt="Cake"
              draggable="false"
            />
          </div>
          <div class="right-container">
            <img
              id="heart_image"
              src="./image/heart.png"
              alt="Heart"
              draggable="false"
            />
            <div class="text-container move-to-right h-10">
              <span class="tittle-action">Pastel</span>
              <span class="hour-action">10:00 PM</span>
            </div>
          </div>
        </div>
        <div id="carnival-container">
          <div class="left-container">
            <img
              id="heart_image"
              src="./image/heart.png"
              alt="Heart"
              draggable="false"
            />
            <div class="text-container move-to-right h-10">
              <span class="tittle-action">Carnaval</span>
              <span class="hour-action">10:15 PM</span>
            </div>
          </div>
          <div class="right-container">
            <img
              id="carnival"
              src="./image/carnival.png"
              alt="Carnival"
              draggable="false"
            />
          </div>
        </div>
        <img
          id="flowers-bottom-right"
          src="./image/flowers_bottom_right.png"
          alt="Flowers bottom Right"
          draggable="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ItineraryView",
};
</script>
<style scoped>
#itinerary-container {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#container {
  background-color: #fcf6f1;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

#first-part-itinerary {
  height: 550px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#second-part-itinerary {
  height: 175px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#third-part-itinerary {
  height: 950px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#line-image {
  position: absolute;
  margin-top: 350px;
  height: 1225px;
  margin-left: 37%;
}

#flowers-top-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  margin-top: 20%;
}

#flowers-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25%;
  margin-top: 10%;
}

#flowers-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 16%;
  margin-bottom: 45px;
}

#flowers-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70px;
  margin-bottom: 430px;
}

#ceremonia-recepcion {
  padding-top: 10%;
}

/*
///////////////////////////////////////////////////////////////
///////////////////////  ITINERARIO  //////////////////////////
///////////////////////////////////////////////////////////////
*/

#itinerario-container {
  display: flex;
  align-items: center;
}

.hr-itinerario {
  width: 35%;
  border: none;
  background-color: #9b9692;
  height: 1px;
}

#itinerario-text-container {
  margin: 0 10%;
}

#itinerario-text {
  font-family: "DM Serif Text";
  color: #89000a;
  font-size: 21.3684px;
  line-height: 29px;
}

/*
///////////////////////////////////////////////////////////////
///////////////////////  ITINERARIO  //////////////////////////
///////////////////////////////////////////////////////////////
*/

/*
///////////////////////////////////////////////////////////////
//////////////////  CEREMONIA - RECEPCION  ////////////////////
///////////////////////////////////////////////////////////////
*/

.text-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.tittle-action {
  font-family: "DM Serif Text";
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #89000a;
}

.hour-action {
  font-family: "DM Serif Text";
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #9b9692;
}

#church-reception {
  width: 100%;
}

#church-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 50px;
}

#church_image {
  width: 30%;
  margin: 0 auto !important;
}

#reception-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 70px;
}

#wine-glasses {
  width: 50%;
  margin-left: 20%;
}

#heart_image {
  width: 21%;
  margin-left: 40%;
}

.right-container {
  margin-right: 15%;
  padding-top: 30px;
}
/*
///////////////////////////////////////////////////////////////
//////////////////  CEREMONIA - RECEPCION  ////////////////////
///////////////////////////////////////////////////////////////
*/
/*
///////////////////////////////////////////////////////////////
//////////////////////  photography  //////////////////////////
///////////////////////////////////////////////////////////////
*/
#photography-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#photography-container .left-container {
  margin-top: 40px;
}

#camera {
  width: 80%;
  margin-left: 30%;
  margin-top: 2%;
}
/*
///////////////////////////////////////////////////////////////
//////////////////////  photography  //////////////////////////
///////////////////////////////////////////////////////////////
*/
/*
///////////////////////////////////////////////////////////////
////////////////////////  dinner  /////////////////////////////
///////////////////////////////////////////////////////////////
*/

#dinner {
  width: 76%;
  margin-left: 8%;
  margin-top: 5%;
}

#dinner-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 30px;
}
/*
///////////////////////////////////////////////////////////////
////////////////////////  dinner  /////////////////////////////
///////////////////////////////////////////////////////////////
*/
/*
///////////////////////////////////////////////////////////////
/////////////////////////  dance  /////////////////////////////
///////////////////////////////////////////////////////////////
*/

#dance-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 70px;
}

#dance {
  width: 85%;
  margin-left: 7%;
}

#dance-container .left-container {
  margin-top: 40px;
}

/*
///////////////////////////////////////////////////////////////
/////////////////////////  dance  /////////////////////////////
///////////////////////////////////////////////////////////////
*/
/*
///////////////////////////////////////////////////////////////
//////////////////////////  cake  /////////////////////////////
///////////////////////////////////////////////////////////////
*/

#cake-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 80px;
}

#cake {
  width: 70%;
  margin-left: 15%;
  margin-top: 5%;
}
/*
///////////////////////////////////////////////////////////////
//////////////////////////  cake  /////////////////////////////
///////////////////////////////////////////////////////////////
*/
/*
///////////////////////////////////////////////////////////////
/////////////////////////  carnival  //////////////////////////
///////////////////////////////////////////////////////////////
*/

#carnival-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
}

#carnival {
  width: 85%;
}

#carnival-container .left-container {
  margin-top: 80px;
}

/*
///////////////////////////////////////////////////////////////
/////////////////////////  carnival  //////////////////////////
///////////////////////////////////////////////////////////////
*/
</style>

<template>
  <EnvelopeView :showInvitationViewFunction="showInvitationViewFunction" />
  <div v-if="showInvitationView">
    <InvitationView
      :information="information"
      :sendResponseToAttend="sendResponseToAttend"
    />
  </div>
</template>

<script>
import EnvelopeView from "./components/EnvelopeView.vue";
import InvitationView from "./components/InvitationView.vue";

import { useRoute } from "vue-router";
import axios from "axios";
import { ref, watchEffect } from "vue";

export default {
  name: "App",
  components: {
    EnvelopeView,
    InvitationView,
  },
  setup() {
    const route = useRoute();

    const id = ref(route.params.id);
    const showInvitationView = ref(false);
    const information = ref({});
    const baseUrl =
      "https://backendinvitaciones.vladimirmoreno.com/controllers";

    const getInformation = async () => {
      if (route.params.id !== undefined) {
        var formData = new FormData();
        if (Number.isInteger(route.params.id)) {
          formData.append("id_url", route.params.id);
        } else {
          formData.append("id_url", parseInt(route.params.id));
        }

        await axios
          .post(baseUrl + "/getGuestInformation.controller.php", formData)
          .then((response) => {
            const data = response.data;
            if (data != null) {
              information.value = data["data"];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    watchEffect(() => {
      if (route.params !== null && route.params.id !== undefined) {
        getInformation();
      }
    });

    return {
      id,
      information,
      baseUrl,
      showInvitationView,
    };
  },
  methods: {
    async getInformation() {
      var formData = new FormData();
      if (Number.isInteger(this.id)) {
        formData.append("id_url", this.id);
      } else {
        formData.append("id_url", parseInt(this.id));
      }

      await axios
        .post(this.baseUrl + "/getGuestInformation.controller.php", formData)
        .then((response) => {
          const data = response.data;
          if (data != null) {
            this.information.value = data["data"];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async sendResponseToAttend(parameter) {
      var formData = new FormData();
      formData.append("id_invitado", parseInt(this.information["id_invitado"]));
      formData.append("confirmacion_asistencia", parseInt(parameter));

      await axios
        .post(this.baseUrl + "/updateAttendance.controller.php", formData)
        .then(() => {
          console.log("Gracias por confirmar");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showInvitationViewFunction() {
      this.showInvitationView = true;
    },
  },
};
</script>

<style>
body,
html {
  margin: 0 !important;
  padding: 0 !important;
  max-height: 100vh !important;
}
</style>
